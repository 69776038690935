<template>
  <v-navigation-drawer v-model="drawerValue" fixed temporary app clipped>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <v-img
            contain
            height="40"
            alt="Sinergia Médica Logo"
            class="shrink mr-2"
            transition="scale-transition"
            :src="require('@/assets/logo_full.png')"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list dense nav>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :to="{ name: item.link }"
        v-if="item.visible"
        link
      >
        <v-list-item-icon class="mr-3">
          <v-icon v-text="item.icon" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["drawer"],
  data() {
    return {
      login: this.$store.getters.getLogin,
      items: [],
    };
  },
  computed: {
    drawerValue: {
      get: function () {
        return this.drawer;
      },
      set: function (v) {
        this.$emit("update:drawer", v);
      },
    },
  },
  mounted() {
    this.items = [
      {
        title: "Inicio",
        icon: "mdi-home",
        link: "home",
        visible: true,
      },
      {
        title: "ASEG. MIGRACIÓN",
        link: "insurance.insurance_migrations",
        icon: "mdi-file-table",
        visible: this.login.role_id == 6,
      },
      {
        title: "RESTITUCIONES",
        link: "restitutions",
        icon: "mdi-file",
        visible: this.login.role_id == 6,
      },
      {
        title: "REPORTE UNIFICADO",
        link: "insurance.report",
        icon: "mdi-file",
        visible: this.login.role_id == 6,
      },
      {
        title: "Reporte ingresos",
        icon: "mdi-file",
        link: "reports.rhas",
        visible: this.login.role_id == 6,
      },
      {
        title: "Reporte servicios",
        icon: "mdi-file",
        link: "reports.rss",
        visible: this.login.role_id == 6,
      },
      // {
      //   title: "Estadistica",
      //   icon: "mdi-file",
      //   link: "reports.rhas_statistics",
      //   visible: this.login.role_id == 6,
      // },
      // {
      //   title: "Restitución",
      //   icon: "mdi-file",
      //   link: "reports.rhas_restitution",
      //   visible: this.login.role_id == 6,
      // },
      // {
      //   title: "Restitución resumen",
      //   icon: "mdi-file",
      //   link: "reports.rhas_restitution_resumen",
      //   visible: this.login.role_id == 6,
      // },
      {
        title: "Reporte ingresos",
        icon: "mdi-file",
        link: "reports.rhas_contractor",
        visible: this.login.role_id == 8,
      },
      {
        title: "Reporte servicios",
        icon: "mdi-file",
        link: "reports.rss_contractor",
        visible: this.login.role_id == 8,
      },
    ];
  },
};
</script>

<style>
</style>