import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  //USER INSURANCE
  //restitutions
  {
    path: "/restituciones",
    name: "restitutions",
    component: () => import("../views/insurance/Restitutions.vue"),
    meta: {
      title: "RESTITUCIONES",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 6,
    },
  },
  {
    path: "/restituciones/:id",
    name: "restitutions.show",
    component: () => import("../views/insurance/RestitutionsShow.vue"),
    meta: {
      title: "RESTITUCIONES | DETALLE",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 6,
    },
  },
  //insurance_migrations
  {
    path: "/aseguradora/asegurados_migracion",
    name: "insurance.insurance_migrations",
    component: () => import("../views/insurance/InsuranceMigrations.vue"),
    meta: {
      title: "ASEG. MIGRACIÓN",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 6,
    },
  },
  //reports
  {
    path: "/aseguradora/reporte",
    name: "insurance.report",
    component: () => import("../views/insurance/Report.vue"),
    meta: {
      title: "REPORTE UNIFICADO",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 6,
    },
  },
  //--------------
  {
    path: "/reportes/ingresos",
    name: "reports.rhas",
    component: () => import("../views/reports/Rhas.vue"),
    meta: {
      title: "Reporte ingresos",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 6,
    },
  },
  {
    path: "/reportes/servicios",
    name: "reports.rss",
    component: () => import("../views/reports/Rss.vue"),
    meta: {
      title: "Reporte servicios",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 6,
    },
  },
  {
    path: "/reportes/ingresos_contratantes",
    name: "reports.rhas_contractor",
    component: () => import("../views/reports/RhasContractor.vue"),
    meta: {
      title: "Reporte ingresos",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 8,
    },
  },
  {
    path: "/reportes/servicios_contratantes",
    name: "reports.rss_contractor",
    component: () => import("../views/reports/RssContractor.vue"),
    meta: {
      title: "Reporte servicios",
      requiresAuth: true,
      permission: store.getters.getLogin.role_id == 8,
    },
  },
  //general
  {
    path: "/",
    name: "main",
    component: () => import("../views/general/Main.vue"),
    meta: { title: "SM ONLINE" },
  },
  {
    path: "/inicio_sesion",
    name: "login",
    component: () => import("../views/general/Login.vue"),
    meta: { title: "SM ONLINE" },
  },
  {
    path: "/inicio",
    name: "home",
    component: () => import("../views/general/Home.vue"),
    meta: { title: "SM ONLINE", requiresAuth: true, permission: true },
  },
  {
    path: "/acceso_denegado",
    name: "unauthorized",
    component: () => import("../views/general/Unauthorized.vue"),
    meta: { title: "Acceso denegado", requiresAuth: true, permission: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not_found",
    component: () => import("../views/general/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// function setPermission(interface_atr, permission) {
//   if (store.getters.getLogin.permissions) {
//     if (store.getters.getLogin.permissions[interface_atr]) {
//       return store.getters.getLogin.permissions[interface_atr][permission]
//     }
//     return false
//   }
//   return false
// }

router.beforeEach((to, from, next) => {
  let auth = store.getters.getLogin.auth;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.matched.some((record) => record.meta.permission)) {
      if (auth) {
        next();
      } else {
        next({ name: "login" });
      }
    } else {
      next({ name: "unauthorized" });
    }
  } else {
    if (auth) {
      next({ name: "home" });
    } else {
      next();
    }
  }

  document.title = to.meta.title;
});

export default router;
